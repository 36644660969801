var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({ background: _vm.isSPA ? 'white' : 'transparent' })},[_c('ApNotificationBar',{attrs:{"messages":_vm.snackbars},on:{"remove":function($event){return _vm.cleanSnackbar($event)}}}),_c('SnackBar'),(_vm.isLoadingConnections)?_c('ApHorizontalLoading',{staticClass:"ma-16",attrs:{"text":"Loading..."}}):((_vm.user && _vm.user.id) || _vm.isPOCRoute || !_vm.context)?_c('router-view',{ref:"appContainer",staticClass:"cursor-default"}):_vm._e(),(_vm.isSPA && _vm.$route.meta.isSetup !== true)?_c('DevFeaturesSpeedDialog'):_vm._e(),(_vm.isSPA && _vm.isSessionExpired)?_c('DevRefreshSessionDialog'):(_vm.isSessionExpired)?_c('ApDialog',{attrs:{"value":_vm.isSessionExpired,"title":"Your Session has expired!","content":"Please refresh the page to start a new one.","is-close-icon-visible":false}}):(
      _vm.timestamp &&
      _vm.lastRequestTimestamp &&
      ![
        'find-duplicates',
        'record-audit-trail',
        'admin-general-settings-home',
      ].includes(_vm.$route.name)
    )?_c('ApDialog',{attrs:{"title":"Your Session is about to expire!","content":"You are inactive for more than one hour and your session is about to expire.","value":_vm.timestamp > _vm.lastRequestTimestamp,"is-close-icon-visible":false,"actions":[
      {
        event: 'keep-alive',
        label: 'Keep Alive',
        color: 'primary',
        loading: _vm.isLoadingUserInfo,
      },
    ]},on:{"keep-alive":_vm.getUserInfo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }