<template>
  <v-app :style="{ background: isSPA ? 'white' : 'transparent' }">
    <ApNotificationBar :messages="snackbars" @remove="cleanSnackbar($event)" />

    <SnackBar />

    <ApHorizontalLoading
      v-if="isLoadingConnections"
      class="ma-16"
      text="Loading..."
    />

    <router-view
      v-else-if="(user && user.id) || isPOCRoute || !context"
      ref="appContainer"
      class="cursor-default"
    />

    <DevFeaturesSpeedDialog v-if="isSPA && $route.meta.isSetup !== true" />

    <DevRefreshSessionDialog v-if="isSPA && isSessionExpired" />

    <ApDialog
      v-else-if="isSessionExpired"
      :value="isSessionExpired"
      title="Your Session has expired!"
      content="Please refresh the page to start a new one."
      :is-close-icon-visible="false"
    />

    <ApDialog
      v-else-if="
        timestamp &&
        lastRequestTimestamp &&
        ![
          'find-duplicates',
          'record-audit-trail',
          'admin-general-settings-home',
        ].includes($route.name)
      "
      title="Your Session is about to expire!"
      content="You are inactive for more than one hour and your session is about to expire."
      :value="timestamp > lastRequestTimestamp"
      :is-close-icon-visible="false"
      :actions="[
        {
          event: 'keep-alive',
          label: 'Keep Alive',
          color: 'primary',
          loading: isLoadingUserInfo,
        },
      ]"
      @keep-alive="getUserInfo"
    />
  </v-app>
</template>

<script>
import SnackBar from './components/shared/SnackBar'
import ApNotificationBar from './components/common/ApNotificationBar'
import ApHorizontalLoading from '@/components/common/ApHorizontalLoading'
import ApDialog from '@/components/common/ApDialog'
import { mapState, mapActions } from 'vuex'
import { generateJwt } from './services/auth'
import { sendBusMessage } from './services/message-bus'
import { isSPA } from '@/utils/env'
import { useTimestamp } from '@vueuse/core'

export default {
  components: {
    ApDialog,
    SnackBar,
    ApNotificationBar,
    ApHorizontalLoading,
    DevRefreshSessionDialog: () =>
      import('@/components/dev/DevRefreshSessionDialog'),
    DevFeaturesSpeedDialog: () =>
      import('@/components/dev/DevFeaturesSpeedDialog'),
  },
  props: {
    context: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSPA,
      resizeInterval: null,
      lastHeightSent: null,
      isLoadingConnections: false,
      isLoadingUserInfo: false,
      timestamp: null,
    }
  },
  computed: {
    ...mapState('auth', [
      'isHerokuRequired',
      'isStepperFinished',
      'user',
      'isSessionExpired',
      'contextMessage',
      'jwt',
      'lastRequestTimestamp',
    ]),
    ...mapState('app', ['snackbars']),
    isPOCRoute() {
      return this.$route.name === 'on-entry-find-duplicates'
    },
  },
  async created() {
    if (this.context) {
      const token = await generateJwt(this.context)

      await this.setJwt(token)

      this.loadContext(this.context)
    }

    this.timestamp = useTimestamp()
  },
  mounted() {
    if (this.context) {
      this.resizeInterval = setInterval(this.sendWindowHeight, 1000)

      // Used for standalone version
      window.onbeforeunload = () => clearInterval(this.resizeInterval)
    }
  },
  methods: {
    ...mapActions('auth', [
      'getMe',
      'getStepper',
      'setJwt',
      'setContextMessage',
    ]),
    ...mapActions('app', ['cleanSnackbar']),
    ...mapActions('general-settings', ['getConnections']),
    async loadContext(message) {
      await this.setContextMessage(message)

      if (!this.isPOCRoute) {
        this.isLoadingConnections = true
        await this.getUserInfo()
        await this.getConnections()
        this.isLoadingConnections = false
      }
    },
    async getUserInfo() {
      this.isLoadingUserInfo = true
      await this.getStepper()
      await this.getMe()

      if (!this.isStepperFinished && !this.isHerokuRequired) {
        this.$router.push({ name: 'admin-general-settings-home' })
      }
      this.isLoadingUserInfo = false
    },
    sendWindowHeight() {
      const containerElement = this.$refs.appContainer
      if (containerElement) {
        const newHeight = containerElement.$el.offsetHeight

        if (this.contextMessage.packageVersion > 0) {
          if (newHeight !== this.lastHeightSent) {
            sendBusMessage({
              action: 'resizeFrame',
              source: this.$route.name,
              height: `${newHeight}px`,
            })

            this.lastHeightSent = newHeight
          }
        } else if (newHeight !== this.lastHeightSent) {
          sendBusMessage({
            action: 'resizeFrame',
            source: this.$route.name,
            height: newHeight,
          })

          this.lastHeightSent = newHeight
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '../node_modules/typeface-roboto/index.css';
</style>
